import { useState } from "react";
import { Form, FormLabel, Stack, Container, Button, Card } from "react-bootstrap";
// import { Editor, EditorState, RichUtils } from "draft-js";
import 'draft-js/dist/Draft.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import College, { collegeConverter } from "../pages/CollegeObj.js"
import { doc, setDoc, getDoc, onSnapshot } from "firebase/firestore";
import { Navigate } from "react-router-dom";
// import URLSearchParams from "react-router-dom";


function Add(props) {
    const urlParams = new URLSearchParams(window.location.search);
    const docName = urlParams.get("docname");
     

    const [isAddingTest, setIsAddingTest] = useState();

    // getDocOk() ? a.get() : 5
    
    const [college, setCollege] = useState();
    const [location, setLocation] = useState();
    const [score, setScore] = useState();
    const [testScore, setTestScore] = useState();
    const [testType, setTestType] = useState("act");
    const [gpa, setGpa] = useState();
    const [notes, setNotes] = useState();

    const [security, setSecurity] = useState();
    const [housing, setHousing] = useState();
    const [food, setFood] = useState();
    const [academics, setAcademics] = useState();
    const [studentLife, setStudentLife] = useState();
    const [majors, setMajors] = useState();
    const [size, setSize] = useState();
    const [goodLocation, setGoodLocation] = useState();

    const [leavePage, setLeavePage] = useState(false);

    var newID = Math.random().toString(36).substring(2);
    
    // if (docName && docName != null) {
    //     newID = docName;
    //     // console.log(docName.toString());
    //     const one = doc(props.db, props.uid, docName.toString());
    //     // console.log("one");
    //     const two = getDoc(one);
    //     // console.log("two");
    //     const db = onSnapshot(one, (e) => {
    //         return e.data();
    //     });
    //     // console.log("three")
    //     const a =  db.cAttributes;

    //     console.log("a");
    //     console.log(a);

    //     setGpa(db.avgGPA);
    //     setAcademics(a.Academics);
    //     setFood(a.get("Food"));
    //     setHousing(a.get("Housing"))
    //     setLocation(a.get("Location"));
    //     setMajors(a.get("Majors"));
    //     setSecurity(a.get("Security"));
    //     setSize(a.get("Size"));
    //     setStudentLife(a.get("Student Life"));
        
    //     setCollege(db.cname);
    //     setLocation(db.location);
    //     setScore(db.myScore);
    //     setNotes(db.notes);
    //     setTestScore(db.testScores);
    //     setTestType(db.testType)
    // }


    function submitHandler() {
        var data = new College(
            college,
            location,
            score,
            testScore,
            testType,
            gpa,
            notes,
            {
                "Security": security,
                "Housing": housing,
                "Food": food,
                "Academics": academics,
                "Activities": studentLife,
                "Majors": majors,
                "Size": size,
                "Location": goodLocation,
            },
            newID
            )
        try {
            setDoc(doc(props.db, props.uid, newID.toString()), collegeConverter.toFirestore(data));
            setLeavePage(true);
        } catch (error) {
            console.log(error);
            window.alert("Please fill all fields!");
        }

    }

    return <Container>
        <Stack gap={2}>
        <Card><Card.Body><p><b><i>All fields required!</i></b></p></Card.Body></Card>
        <Form>
            <FormLabel>Enter your college...</FormLabel>
            <Form.Control required value={college} type='text' onChange={(e) => {setCollege(e.target.value)}}></Form.Control>

            <FormLabel>Enter the location...</FormLabel>
            <Form.Control required type="text" value={location} onChange={(e) => {setLocation(e.target.value)}}></Form.Control>

            <FormLabel>Select your score (out of 10)...</FormLabel>
            <Form.Control required value={score} onChange={(x) => {setScore((x.target.value))}}></Form.Control>
            
            <ASlider val={security} title="Safety" setter={setSecurity} />
            <ASlider val={housing} title="Housing" setter={setHousing} />
            <ASlider val={food} title="Food" setter={setFood} />
            <ASlider val={academics} title="Academics" setter={setAcademics} />
            <ASlider val={studentLife} title="Student Life" setter={setStudentLife} />
            <ASlider val={majors} title="Majors" setter={setMajors} />
            <ASlider val={size} title="Size" setter={setSize} />
            <ASlider val={goodLocation} title="Location" setter={setGoodLocation} />

            <FormLabel>Enter your test score...</FormLabel>
            <Form.Control required type="number" onChange={(e) => {setTestScore(e.target.value); setIsAddingTest(true)}}></Form.Control>
            {isAddingTest ? (
                <>
                <FormLabel>Select the type of test...</FormLabel>
                <Form.Select required onChange={(e) => {setTestType(e.target.value)}}>
                        <option value="act">ACT</option>
                        <option value="sat">SAT</option>
                    </Form.Select></>
            ) : (<><p></p></>)}
            
            <FormLabel>Enter the average GPA...</FormLabel>
            <Form.Control required type="number" onChange={(e) => {setGpa(e.target.value)}}></Form.Control>
            
            <FormLabel>Add notes</FormLabel>
            <Form.Control required as="textarea" style={{resize:'vertical'}} onChange={(e) => {setNotes(e.target.value)}}></Form.Control>
            <Button onClick={submitHandler}>Save!</Button>
            {leavePage && <><Navigate to="/" /></>}
        </Form>
        </Stack>
    </Container>
}

function ASlider(props) {
    // const [num, setNum] = useState(5);

    return <>
        <FormLabel>{props.title}... </FormLabel>
        <Form.Control required type="number" value={props.val} onChange={(x) => {props.setter((x.target.value))}}></Form.Control>
    </>
}

export default Add;