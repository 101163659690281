import { Card, Container, Col, Row, Button } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { doc, deleteDoc } from 'firebase/firestore'
// import List from "./List";
// import Add from "./Add";


function CollegeCard(props) {
    
    // for debugging
    // useEffect(() => {
    //     const attributes = Array.from(props.cAttributes);
    //     // console.log(props.cAttributes)
    //     // console.log(Object.keys(props.cAttributes));
    //     Object.keys(props.cAttributes).forEach(element => {
            
    //     });
    // })

    return (
        <div style={{padding: 3}}>
        <Card>
            <Card.Body>
                <Container>
                    <Row><Col>
                        <h3>{props.college}</h3>
                        <p>Location: {props.location}</p>
                        <p>My Score: {props.score}</p>
                        <p>Test Scores: {props.testScores} ({props.testType})</p>
                        <p>GPA: {props.avgGPA}</p>
                    </Col>
                    <Col>
                    {/* Made possible by https://www.educative.io/answers/how-to-get-keys-values-and-entries-in-javascript-object */}
                    {Object.entries(props.cAttributes)?.map(([a,b]) => {
                            return <p>{a}: {b}</p>
                        })}
                        <p>Notes: {props.notes}</p>
                        <Button variant="danger" onClick={() => {
                            var one = props.db;
                            var two = props.uid;
                            var three = props.docname;
                            console.log(one, " ", two, " ", three);
                            if (window.confirm("Are you sure you want to delete this?")) {
                                deleteDoc(doc(props.db, props.uid, props.docname));
                                // window.alert();
                                // window.location.reload();
                            }
                        }}>Delete?</Button>
                    </Col></Row>
                </Container>
            </Card.Body>
        </Card>
        </div>
    )
}

export default CollegeCard;