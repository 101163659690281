import { auth, storage } from "../App";

export default class College {
    cname;
    location;
    myScore;
    testScores;
    testType;
    avgGPA;
    notes;
    cAttributes;
    id;
    constructor(cname, location, myScore, testScores, testType, avgGPA, notes, cAttributes, id) {
        this.cname = cname;
        this.location = location;
        this.myScore = myScore; 
        this.testScores = testScores;
        this.testType = testType;
        this.avgGPA = avgGPA;
        this.notes = notes;
        this.cAttributes = cAttributes
        this.id = id;
    }
}

export const collegeConverter = {
    toFirestore: (file) => {
        return {
            cname: file.cname,
            location: file.location,
            myScore: file.myScore,
            testScores: file.testScores,
            testType: file.testType,
            avgGPA: file.avgGPA, 
            notes: file.notes,
            cAttributes: file.cAttributes,
            id: file.id
        }
    },
    fromFirestore: (snap, opt) => {
        const file = snap.data(opt);
        return new College(file.cname, file.location, file.myScore, file.testScores, file.testType, file.avgGPA, file.notes, file.cAttributes, file.id);
    }
}