import { collection } from "firebase/firestore";
import CollegeCard from "./CollegeCards";
import { useCollectionData } from "react-firebase-hooks/firestore"
import { Card, Container } from "react-bootstrap";
import { auth } from "../App";

function List(props) {
    // https://youtu.be/gm-bggVJb5k?t=957
    const data = collection(props.db, props.uid);
    const [docs, loading, error] = useCollectionData(data);
    return <>
    <Container>
        <Card><Card.Body><p>Click "Add a College" above to create a new college.</p></Card.Body></Card>
        {docs?.map((d) => (
          <CollegeCard college={d.cname} location={d.location} score={d.myScore} notes={d.notes} 
          testScores={d.testScores} testType={d.testType} avgGPA={d.avgGPA} cAttributes={d.cAttributes} 
          docname={d.id} uid={auth.currentUser.uid} db={props.db}
          />
        ))}
    </Container>
    </>;
}

export default List;