import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import './pages/Add'
import Add from './pages/Add';
import List from './pages/List';
import { useEffect, useState } from 'react';
import { signInWithEmailAndPassword, getAuth, signInWithPopup } from 'firebase/auth';
import { Button } from 'react-bootstrap';
import { getApp } from '@firebase/app';
import { initializeApp } from '@firebase/app';
import 'firebase/firestore'
import 'firebase/auth'
import { GoogleAuthProvider} from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirestore } from 'firebase/firestore';
import { doc, getDoc, DocumentData } from 'firebase/firestore';
import { onSnapshot } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyA2OIAf-dhf26WjHXv7RS7mQqd-d5CDXVE",
  authDomain: "collegetrakapp.firebaseapp.com",
  projectId: "collegetrakapp",
  storageBucket: "collegetrakapp.appspot.com",
  messagingSenderId: "341872641857",
  appId: "1:341872641857:web:3bc5d257dec523c48f03c0",
  measurementId: "G-C62WENRTTM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
const db = getFirestore();

// hi
function App() {
  const [person] = useAuthState(auth);
  
  return (
    <div className="App">
      <BrowserRouter>
      {person ? <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand>CollegeTrak</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">My Colleges</Nav.Link>
              <Nav.Link href="/add">Add a College</Nav.Link>
              <Nav.Link>Welcome, {person.displayName}</Nav.Link>
              <Logout />
            </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar> : <><Login /></>}
        {person && <Routes> 
          <Route index path='/' Component={() => {
            return <List db={db} person={person} uid={auth.currentUser.uid} />
          }} />
          <Route path='/add' Component={() => {
            return <Add db={db} person={person} uid={auth.currentUser.uid} />
          }}/>
        </Routes>}
      </BrowserRouter>
    </div>
  );
}

function Login(props) {
  const googleIn = () => {
    const p = new GoogleAuthProvider();
    signInWithPopup(auth, p);
  } 

  return (
  <><>  <Button onClick={googleIn}>Click Here for Gmail Sign In</Button>
  <h3>If you have already signed in, do not click this button!</h3>
  <h3>Refresh the page if you are signed in and stuck</h3></> 

  </>)
}

function Logout() {
  return (auth.currentUser && (
    <Button onClick={() => auth.signOut()}>Sign Out</Button>
  ))
}



export default App;
